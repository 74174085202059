<template>
  <div>
      <v-alert
        bottom
        left
        dense
        type="success"
        style="z-index:99; position: fixed; left: 16px; bottom: 0px;"
        v-model="displayAlert"
      >
        {{this.alertText}}
      </v-alert>
      <div class="shareButtons">
        <v-row style="padding: 8px; padding-left: 6px;">
          <v-icon>mdi-share</v-icon>
        </v-row>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-row v-bind="attrs" @click="shareViaEmail()"
            v-on="on" style="padding: 8px; padding-left: 6px;"><v-icon class="socialButton">mdi-email</v-icon></v-row>
          </template>
          <span>{{ $t("share.email") }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-row v-bind="attrs" @click="shareViaFB()"
            v-on="on" style="padding: 8px; padding-left: 6px;"><v-icon class="socialButton">mdi-facebook</v-icon></v-row>
          </template>
          <span>{{ $t("share.facebook") }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-row v-bind="attrs" @click="shareViaTwitter()"
            v-on="on" style="padding: 8px; padding-left: 6px;"><v-icon class="socialButton">mdi-twitter</v-icon></v-row>
          </template>
          <span>{{ $t("share.twitter") }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-row v-bind="attrs" @click="shareViaCopy()"
            v-on="on" style="padding: 8px; padding-left: 6px;"><v-icon class="socialButton">mdi-link</v-icon></v-row>
          </template>
          <span>{{ $t("share.copyPasteText") }}</span>
        </v-tooltip>
      </div>
  </div>
</template>

<style scoped>
.shareButtons {
  position: fixed;
  left: 0px;
  z-index: 9999999;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  top: 150px;
  width: 32px;
  padding: 8px;
  background-color: white;
  opacity: 0.5;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

.shareButtons:hover {
  opacity: 1;
}

.socialButton:hover {
  color:#aa004c
}
</style>

<script>
export default {
  methods: {
    shareViaEmail () {
      let subject = "Sharing Tentifly with you!";
      let body = 'Please check out <a href="www.tentifly.com">www.tentifly.com</a>.';
      let subjectEncoded = encodeURIComponent(subject);
      let bodyEncoded = encodeURIComponent(body);
      location.href = "mailto:?subject=" + subjectEncoded + "&body=" + bodyEncoded;
      this.alertText="Shared vie email."
      this.displayAlert = true;
      let self = this;
      setTimeout(function () {
        self.displayAlert = false;
      }, 5000);
    },
    shareViaFB () {
      location.href = "https://www.facebook.com/sharer/sharer.php?u=tentifly.com";
      this.alertText="Shared vie Facebook."
      this.displayAlert = true;
      let self = this;
      setTimeout(function () {
        self.displayAlert = false;
      }, 5000);
    },
    shareViaTwitter () {
      location.href = "http://twitter.com/share?text=Check out Tentifly!&url=https://tentifly.com&hashtags=smart,booking,reservation,onlineSale,pos,onlineShop,erp,assistant,order,sell";
      this.alertText="Shared vie Twitter."
      this.displayAlert = true;
      let self = this;
      setTimeout(function () {
        self.displayAlert = false;
      }, 5000);
    },
    shareViaCopy () {
      navigator.clipboard.writeText("Check out www.tentifly.com!");
      this.alertText="Copied to clipboard."
      this.displayAlert = true;
      let self = this;
      setTimeout(function () {
        self.displayAlert = false;
      }, 5000);
    }
  },
  data: () => ({
    displayAlert: false,
    alertText: "Oops! You should not see this text."
  })
};
</script>
